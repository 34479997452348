<template>
  <div class="container">
    <div class="d-flex justify-space-between flex-md-row flex-column mb-4">
      <v-text-field
        class="mr-md-2 mb-6 mb-md-0"
        v-model="startDate"
        :label="$vuetify.lang.t('$vuetify.base.startDate')"
        type="date"
        clearable
        dense
        hide-details
        outlined
        :rules="[rules.required]"
      ></v-text-field>
      <v-text-field
        class="ml-0 ml-lg-4"
        v-model="endDate"
        :label="$vuetify.lang.t('$vuetify.base.endDate')"
        type="date"
        clearable
        dense
        hide-details
        outlined
        :rules="[rules.dateRange(startDate, endDate)]"
      ></v-text-field>
    </div>
    <div class="d-flex flex-column flex-md-row">
      <v-text-field
        autocomplete="null"
        name="search"
        dense
        v-model="searchTerms"
        @keyup.enter="search"
        placeholder="Search by customer or product name"
        append-icon="mdi-magnify"
        outlined
        clearable
        hide-details
        label="Search"
        class="flex-grow-1"
      />
      <v-select
        outlined
        hide-details
        dense
        label="Order Status"
        v-model="status"
        :items="availableStatuses"
        item-text="name"
        item-value="value"
        multiple
        class="my-2 my-md-0 ml-0 ml-md-4 flex-grow-0"
      />
    </div>
    <div class="d-flex justify-center my-2">
      <v-btn @click="search"> Submit </v-btn>
    </div>
    <div class="text-center text-caption font-weight-light mt-4">
      <v-divider />
      Found {{ this.pagination.totalSize | formatInteger }} open orders
    </div>
    <infinite-scroll
      :key="infiniteScrollKey"
      @fetchData="fetchSalesOrders"
      :totalPages.sync="pagination.totalPages"
    >
      <order-status
        :order="order"
        v-for="order in salesOrders"
        :key="order.id"
      />
    </infinite-scroll>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const OrderStatus = () => import("@/components/order/OrderStatus.vue");
import { fetchOrdersSummary } from "@/services/orderServices";
import { rules } from "@/store/util/rules.js";
import moment from 'moment';

export default {
  data: () => ({
    pagination: {
      totalSize: null,
      totalPages: null,
      pageSize: 10,
    },
    availableStatuses: [
      { name: "Open", value: "OPEN" },
      { name: "Partially Filled", value: "PARTIALLY_FILLED" },
      { name: "Completed", value: "COMPLETED" },
    ],
    pendingPackings: [],
    salesOrders: [],
    infiniteScrollKey: Date.now(),
    status: ["OPEN", "PARTIALLY_FILLED"],
        startDate: null,
    endDate: null,
    rules,
  }),
  components: { OrderStatus },
  created(){
    this.initDefaultDates();
    this.searchTerms = this.$route.query.q;
    if(this.$route.query.start){
      this.startDate = this.$route.query.start;
    }
    if(this.$route.query.end){
      this.endDate = this.$route.query.end;
    }
  },
  methods: {
    ...mapActions("processWorksheet", ["fetchProcessWorksheets"]),
    initDefaultDates() {
      if (!this.startDate) {
        this.startDate = moment().subtract(1, "month").format("YYYY-MM-DD");
      }
      if (!this.endDate) {
        this.endDate = moment().format("YYYY-MM-DD");
      }
    },
    clearSearch() {
      this.searchTerms = null;
      this.resetResults();
    },
    search() {
      this.updateQueryParam();
      this.resetResults();
    },
    resetResults() {
      this.salesOrders = [];
      this.pagination.totalPages = null;
      this.infiniteScrollKey = Date.now();
    },
    async fetchSalesOrders({ page, done, empty }) {
      const params = {
        page,
        size: this.pagination.pageSize,
        sort: "id,desc",
        searchTerms: this.searchTerms,
        statuses: this.status ? this.status.join(",") : null,
        start: this.startDate,
        end: this.endDate,
      };
      return await this.$axios.get("/salesOrder", { params }).then((resp) => {
        const salesOrders = resp.data.content;
        this.pagination.totalSize = resp.data.totalElements;
        this.pagination.totalPages = resp.data.totalPages;

        if (salesOrders && salesOrders.length > 0) {
          fetchOrdersSummary(salesOrders).then((ordersSummary) => {
            ordersSummary.forEach((s) => {
              const order = salesOrders.find((o) => o.id == s.orderId);
              if (order.summaries) {
                const list = order.summaries;
                list.push(s);
                this.$set(order, "summaries", list);
              } else {
                const list = [s];
                this.$set(order, "summaries", list);
              }
            });
          });

          done();
        } else {
          empty();
        }

        this.salesOrders = this.salesOrders.concat(salesOrders);
      });
    },
    updateQueryParam() {
      const currentRoute = this.$route;
      const query = Object.assign({}, currentRoute.query); // Make a copy of the query object
      query.q = this.searchTerms; 
      query.start = this.startDate;
      query.end = this.endDate;
      this.$router.replace({ query }); // Replace the current route with a new route that has the updated query parameter
    },
    // fetchOrdersSummary(orders) {
    //   if (orders && orders.length > 0) {
    //     const orderIds = orders.map((order) => order.id);
    //     console.log("fetching order summary info: " + orderIds);
    //     this.$axios
    //       .get("/salesOrder/order-status", {
    //         params: { orderIds: orderIds.join(",") },
    //       })
    //       .then((resp) => {
    //         const ordersSummary = resp.data;
    //         if (ordersSummary && ordersSummary.length > 0) {
    //           ordersSummary.forEach((s) => {
    //             const order = orders.find((o) => o.id == s.orderId);
    //             if (order.summaries) {
    //               const list = order.summaries;
    //               list.push(s);
    //               this.$set(order, "summaries", list);
    //             } else {
    //               const list = [s];
    //               this.$set(order, "summaries", list);
    //             }
    //           });
    //         }
    //       });
    //   }
    // },
  },
};
</script>

<style></style>
